import * as React from 'react'

import { Container, Divider, ThemeProvider, createTheme } from '@mui/material'
import { graphql } from 'gatsby'
import * as queryString from 'query-string'

import {
  BlogAuthorProfile,
  BlogPostTitle,
  Section,
  SectionWrapper,
  SeoHeaders,
} from 'shared/components'
import { renderRichText } from 'shared/services'

import Layout from '../../../layouts/articles'

import type { Theme } from '@mui/material'
import type { PageProps } from 'gatsby'
import type { BlogAuthor, BlogImageData } from 'shared/components'
import type { BlogRichText } from 'shared/services'
import '@fontsource/pt-sans/400.css'
import '@fontsource/pt-sans/700.css'

type HeroImage = BlogImageData & {
  url: string
}

const heroOgImageUrl = (heroImage: HeroImage) => (
  queryString.stringifyUrl({
    url: heroImage.url,
    query: {
      w: 1200,
      q: 90,
      fm: 'webp',
    },
  })
)

type BlogPostData = {
  contentfulBlogPost: {
    id: string
    slug: string
    category: string
    createdAt: string
    title: string
    lead: string
    heroImage: HeroImage
    body: BlogRichText
    authors: BlogAuthor[]
  }
}

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      slug
      category
      createdAt
      title
      lead
      heroImage {
        gatsbyImageData(formats: WEBP, width: 900, quality: 90)
        url
      }
      body{
        raw
        references {
          ... on ContentfulAsset{
            __typename
            contentful_id
            gatsbyImageData
            title
          }
        }
      }
      authors {
        name
        avatar {
          gatsbyImageData(formats: WEBP, width: 96, quality:90)
        }
        bio {
          raw
        }
      }
    }
  }
`

const BlogPost = ({ data: { contentfulBlogPost } }: PageProps<BlogPostData>) => {
  const blogPostBody = React.useRef<HTMLDivElement>(null)
  const [scrollProgress, setScrollProgress] = React.useState(0)

  React.useEffect(() => {
    const handleScroll = () => {
      const contentElement = blogPostBody.current

      if (!contentElement) {
        return
      }

      const height = Math.max(
        contentElement.scrollHeight,
        contentElement.offsetHeight,
        contentElement.clientHeight,
      )
      const relativePosition = contentElement.getBoundingClientRect().top
      const scrollPercent = Math.floor(100 * (window.innerHeight - relativePosition) / height)
      setScrollProgress(Math.min(Math.max(scrollPercent, 1), 100))
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Layout
      showProgress
      progressValue={scrollProgress}
    >
      <SeoHeaders
        title={contentfulBlogPost.title}
        description={contentfulBlogPost.lead}
        ogType='article'
        ogImage={heroOgImageUrl(contentfulBlogPost.heroImage)}
        schemaMarkup={{
          '@context': 'https://schema.org',
          '@type': 'BlogPosting',
          'mainEntityOfPage': {
            '@type': 'WebPage',
            '@id': `https://guita.cl/articulos/posts/${contentfulBlogPost.slug}`,
          },
          'headline': contentfulBlogPost.title,
          'description': contentfulBlogPost.lead,
          'image': contentfulBlogPost.heroImage.url,
          'author': {
            '@type': 'Person',
            'name': contentfulBlogPost.authors[0].name,
          },
          'datePublished': contentfulBlogPost.createdAt,
        }}
      />
      <BlogPostTitle
        title={contentfulBlogPost.title}
        lead={contentfulBlogPost.lead}
        category={contentfulBlogPost.category}
        heroImage={contentfulBlogPost.heroImage}
      />
      <Container maxWidth='md'>
        <Divider />
        <SectionWrapper>
          <Section ref={blogPostBody}>
            <ThemeProvider
              theme={(theme: Theme) =>
                createTheme({
                  ...theme,
                  typography: {
                    fontFamily: '"PT Sans", "Roboto", "Helvetica", "Arial", sans-serif',
                    fontSize: 20,
                  },
                })
              }
            >
              {renderRichText(contentfulBlogPost.body)}
            </ThemeProvider>
          </Section>
          <Divider />
          <Section>
            {contentfulBlogPost.authors.map((author, index) => (
              <BlogAuthorProfile
                author={author}
                key={index}
                sx={{ backgroundColor: 'primary.main' }}
              />
            ))}
          </Section>
        </SectionWrapper>
      </Container>
    </Layout>
  )
}

export default BlogPost
